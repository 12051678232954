import { BlockQuote as AlliumBlockQuote } from '@telus-uds/components-web'
import { TextRenderer } from '../../../utils/text/TextRenderer'

export type BlockQuoteProps = {
  textStyle?: 'large' | 'heading'
  text?: string
  link?: string
  linkHref?: string
  additionalInfo?: string
}

const BlockQuote = ({
  textStyle = 'large',
  text = 'Default',
  link = '',
  linkHref = '',
  additionalInfo = '',
}: BlockQuoteProps) => (
  <AlliumBlockQuote
    testID="block-quote-testid"
    textStyle={textStyle}
    additionalInfo={additionalInfo}
    link={link}
    linkHref={linkHref}
  >
    <TextRenderer>{text}</TextRenderer>
  </AlliumBlockQuote>
)

export default BlockQuote
