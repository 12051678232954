import { Span, type TypographyProps } from '@telus-uds/components-web'
import type { VariantColor, VariantSize } from './TypographyVariantTypes'
import type { HeadingTag } from '@telus-uds/components-web/types/common'

export type RichTextBoldProps = {
  children: React.ReactNode
  colour?: VariantColor
  align?: TypographyProps['align']
  size?: VariantSize | HeadingTag
  inverse?: boolean
}

const RichTextBold = ({ align, colour, children, size, inverse }: RichTextBoldProps) => (
  <Span
    align={align}
    variant={{
      bold: true,
      size,
      colour,
      inverse,
    }}
    role="strong"
  >
    {children}
  </Span>
)

export default RichTextBold
