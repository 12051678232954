import { blocks } from '../..'
import { BlockRenderer } from '@/siteBuilder/renderer/contentful/BlockRenderer'
import BlogRenderer from '@/blogBuilder/renderer/BlogRenderer'

import type { Entry } from 'contentful'
import type { NodeRenderer } from '@contentful/rich-text-react-renderer'

type RichTextEmbeddedEntryProps = {
  node: Parameters<NodeRenderer>['0']
}

const RichTextEmbeddedEntry = ({ node }: RichTextEmbeddedEntryProps) => {
  const block = node.data.target as Entry<unknown>

  const contentTypeId = block.sys?.contentType?.sys?.id

  if (Object.keys(blocks).includes(contentTypeId)) return <BlockRenderer block={block} />

  return <BlogRenderer block={block} />
}

export default RichTextEmbeddedEntry
