import React, { ReactNode } from 'react'

import RichTextBold, { type RichTextBoldProps } from './RichTextBold'
import { Link } from '@telus-uds/components-web'

import { cloneElementOfType } from './helpers'

import type { HyperlinkTarget } from './RichText'
import type { HeadingTag } from '@telus-uds/components-web/types/common'

export const HyperLinkSize = ['micro', 'small', 'large']

export type RichTextHyperlinkProps = {
  href: string
  children: ReactNode
  size?: 'large' | 'small' | 'micro' | HeadingTag
  alternative?: boolean
  inverse?: boolean
  target?: HyperlinkTarget
}

const RichTextHyperlink = ({ href, children, size, alternative, inverse, target }: RichTextHyperlinkProps) => {
  if (!children) return null

  if (size && !HyperLinkSize.includes(size)) {
    // if link inside heading render heading without hyperlink,
    // inherit the heading size if child is Bold text or any other component
    return (
      <>
        {React.Children.map(children, (child) => {
          if (!child) return null
          return cloneElementOfType<RichTextBoldProps>(child, { size })
        })}
      </>
    )
  }

  return (
    <Link
      href={href}
      hrefAttrs={{ target }}
      variant={{
        size,
        inverse,
        alternative,
      }}
    >
      {React.Children.map(children, (child) => {
        if (!child) return null
        // TODO: at the moment bold is not supported inside link. remove this when is supported
        return React.isValidElement<RichTextBoldProps>(child) && child.type === RichTextBold
          ? child.props.children
          : child
      })}
    </Link>
  )
}

export default RichTextHyperlink
