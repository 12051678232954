import React from 'react'
import { Typography, type TypographyProps } from '@telus-uds/components-web'
import { cloneElementOfType } from './helpers'

import type { RichTextBoldProps } from './RichTextBold'
import type { RichTextHyperlinkProps } from './RichTextHyperlink'
import type { HeadingTag } from '@telus-uds/components-web/types/common'

type RichTextHeadingProps = {
  heading: HeadingTag
  children: React.ReactNode
  inverse?: boolean
  align?: TypographyProps['align']
}

const RichTextHeading: React.FC<RichTextHeadingProps> = ({ heading, inverse, align = 'auto', children }) => {
  return (
    <Typography
      block
      variant={{
        size: heading,
        inverse: inverse,
      }}
      align={align}
      heading={heading}
    >
      {React.Children.map(children, (child) => {
        if (!child) return null

        // Inherit header size to child components
        return cloneElementOfType<RichTextBoldProps | RichTextHyperlinkProps>(child, { size: heading })
      })}
    </Typography>
  )
}

export default RichTextHeading
