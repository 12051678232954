import React from 'react'
import { BlockNotFound } from 'src/siteBuilder/components/errors/BlockNotFound'

import { blocks } from '../components'

export type BlogRendererProps = {
  block: any
  fieldPath?: string
}

const BlogRenderer = ({ block, fieldPath, ...rest }: BlogRendererProps) => {
  if (!block) {
    return null
  }

  if (Array.isArray(block)) {
    return (
      <>
        {block.map((b, index) => {
          return (
            <BlogRenderer
              key={`${index}-block-${b.sys.id}`}
              fieldPath={`${fieldPath ?? ''}.${index}`}
              block={b}
              {...rest}
            />
          )
        })}
      </>
    )
  }

  const contentTypeId = block.sys?.contentType?.sys?.id
  const Component = blocks[contentTypeId]

  if (!Component) {
    return <BlockNotFound entryId={block?.sys?.id} blockName={contentTypeId} entryTitle={block.fields?.entryTitle} />
  }

  const { id } = block.sys
  const componentProps = {
    ...block,
    ...(fieldPath ? { 'data-sb-field-path': fieldPath } : {}),
    ...rest,
  }

  return <Component key={`${contentTypeId}-${id}`} {...componentProps} />
}

export default BlogRenderer
