import React from 'react'
import { BLOCKS, Document, MARKS, INLINES } from '@contentful/rich-text-types'
import { TextRenderer } from 'src/siteBuilder/utils/text/TextRenderer'
import { Options } from '@contentful/rich-text-react-renderer'
import { RichTextProps } from './RichText'
import RichTextHyperlink, { HyperLinkSize, RichTextHyperlinkProps } from './RichTextHyperlink'
import RichTextList, { ParagraphSizeToListSize, RichTextListProps } from './RichTextList'
import RichTextBold from './RichTextBold'
import RichTextHeading from './RichTextHeading'
import Image from '../../core/Image/Image'
import Divider from '../Divider/Divider'
import RichTextParagraph from './RichTextParagraph'
import { Typography } from '@telus-uds/components-web'
import BlockQuote from '../../core/BlockQuote/BlockQuote'
import BBImage from '../../../../blogBuilder/components/Image'
import RichTextEmbeddedEntry from './RichTextEmbeddedEntry'
import { Asset } from 'contentful'

const supportedTypes = ['image/png', 'image/jpeg']

export const configOptions = ({
  spaceBetween,
  paragraphSize,
  colour,
  align,
  inverse = false,
  linkColour,
  listIcon,
  isBlog,
  target,
}: RichTextProps): Options => ({
  renderText: (text) => {
    // remove empty spaces preserving new lines (\n)
    const trimmedText = text.replace(/^\x20+|\x20+$/gm, '')

    if (text.length === 0 || trimmedText.length === 0) return null

    if (trimmedText === '\n') return <br />

    // use text variable because we want to preserve spaces so other components are not together
    return text.split('\n').reduce((children, textSegment, idx) => {
      const renderedText = <TextRenderer key={'txt-' + idx}>{textSegment}</TextRenderer>
      const newElements = []

      if (idx > 0) newElements.push(<br key={'br-' + idx} />)
      if (renderedText) newElements.push(renderedText)

      return children.concat(newElements)
    }, [])
  },
  renderMark: {
    [MARKS.BOLD]: (children) => {
      if (!children) return null

      const childrenArr = React.Children.toArray(children)

      if (childrenArr.length === 1 && React.isValidElement(childrenArr[0]) && childrenArr[0].type === 'br')
        return children

      return (
        <RichTextBold align={align} colour={colour} inverse={inverse} size={paragraphSize}>
          {children}
        </RichTextBold>
      )
    },
    [MARKS.UNDERLINE]: /* istanbul ignore next */ (children) => (
      <Typography align={align} variant={{ colour, inverse, size: paragraphSize }} tag="em">
        <sup>{children}</sup>
      </Typography>
    ),
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      const alternative = linkColour === 'alternative' ? true : false
      const size = HyperLinkSize.includes(paragraphSize) ? (paragraphSize as RichTextHyperlinkProps['size']) : null

      return (
        <RichTextHyperlink
          size={size}
          inverse={inverse}
          alternative={alternative}
          target={target}
          href={node.data.uri as string}
        >
          {children}
        </RichTextHyperlink>
      )
    },
    [INLINES.EMBEDDED_ENTRY]: (node) => <RichTextEmbeddedEntry node={node} />,
    [BLOCKS.PARAGRAPH]: (node, children) => {
      // Convert empty paragraphs in jump of lines
      if (node.content.length === 1 && node.content[0].nodeType === 'text' && node.content[0].value === '')
        return <br />

      // TODO: figure out why UDS Paragraph does not renders with proper styles after HyperLinks. When using unit test and storybook catalog the Paragraph works well but in contentful and SB2 Paragraph breaks
      return (
        <RichTextParagraph
          testID="rich-text-paragraph"
          linesBetween={spaceBetween}
          size={paragraphSize}
          colour={colour}
          inverse={inverse}
          align={align}
        >
          {children}
        </RichTextParagraph>
      )
    },
    [BLOCKS.HEADING_1]: (_, children) => (
      <RichTextHeading inverse={inverse} heading="h1" align={align}>
        {children}
      </RichTextHeading>
    ),
    [BLOCKS.HEADING_2]: (_, children) => (
      <RichTextHeading inverse={inverse} heading="h2" align={align}>
        {children}
      </RichTextHeading>
    ),
    [BLOCKS.HEADING_3]: (_, children) => (
      <RichTextHeading inverse={inverse} heading="h3" align={align}>
        {children}
      </RichTextHeading>
    ),
    [BLOCKS.HEADING_4]: (_, children) => (
      <RichTextHeading inverse={inverse} heading="h4" align={align}>
        {children}
      </RichTextHeading>
    ),
    [BLOCKS.HEADING_5]: (_, children) => (
      <RichTextHeading inverse={inverse} heading="h5" align={align}>
        {children}
      </RichTextHeading>
    ),
    [BLOCKS.HEADING_6]: (_, children) => (
      <RichTextHeading inverse={inverse} heading="h6" align={align}>
        {children}
      </RichTextHeading>
    ),
    [BLOCKS.HR]: () => <Divider />,
    [BLOCKS.EMBEDDED_ENTRY]: (node) => <RichTextEmbeddedEntry node={node} />,
    [BLOCKS.EMBEDDED_ASSET]: (node: Document) => {
      const fields = (node?.data?.target as Asset)?.fields

      if (!!fields && !!fields.file && !supportedTypes.includes(fields.file.contentType)) return null

      if (isBlog) {
        return (
          <BBImage
            futureImage
            src={fields.file.url}
            alt={fields.description || fields.title}
            height={fields.file.details?.image?.height}
            width={fields.file.details?.image?.width}
            removeWhiteSpace
            blurDataURL={fields.file.url}
            placeholder="blur"
          />
        )
      }

      return (
        <Image
          src={fields.file.url}
          alt={fields.title}
          height={fields.file.details?.image?.height}
          width={fields.file.details?.image?.width}
        />
      )
    },
    [BLOCKS.LIST_ITEM]: (_, children) => children,
    // TODO: @UDS all lists does not support INVERSE. waiting for UDS support
    [BLOCKS.UL_LIST]: (_, children) => {
      const size = ParagraphSizeToListSize[paragraphSize] as RichTextListProps['size']

      return (
        <RichTextList size={size} type="ul" {...listIcon}>
          {children}
        </RichTextList>
      )
    },
    // TODO: @UDS ordered list does not support SIZE. waiting for UDS support
    [BLOCKS.OL_LIST]: (_, children) => {
      const size = ParagraphSizeToListSize[paragraphSize] as RichTextListProps['size']

      return (
        <RichTextList size={size} type="ol">
          {children}
        </RichTextList>
      )
    },
    [BLOCKS.QUOTE]: (node) => {
      const quotes = node.content.flatMap((d) => {
        return d.content
      })

      if (quotes.length > 1) {
        let value = ''
        quotes.forEach((d) => {
          value = value + d.value
        })

        return <BlockQuote text={value} textStyle="large" />
      }

      const quote = quotes.find((d) => d.nodeType === 'text')

      return <BlockQuote text={quote.value} textStyle="large" />
    },
    [BLOCKS.DOCUMENT]: (node, children) => {
      const lastChild = node.content[node.content.length - 1]

      if (
        lastChild.nodeType === 'paragraph' &&
        lastChild.content.length === 1 &&
        lastChild.content[0].nodeType === 'text' &&
        !lastChild.content[0].value
      )
        return React.Children.toArray(children).slice(0, -1)

      return children
    },
  },
})
