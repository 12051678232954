/* istanbul ignore file */

import dynamic from 'next/dynamic'

export const blocks = {
  quote: dynamic(() => import('./Quote/ContentfulQuote')),
  youtubeVideo: dynamic(() => import('./YoutubeVideo/ContentfulYoutubeVideo')),
  imagesSection: dynamic(() => import('./ImagesSection/ContentfulImagesSection')),
  imageLinks: dynamic(() => import('./ImageLinks/ContentfulImageLinks')),
  ctaLink: dynamic(() => import('./CtaLink/ContentfulCtaLink')),
}
