import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Document } from '@contentful/rich-text-types'
import { SpacerProps } from '../Spacer/Spacer'
import { configOptions } from './configOptions'
import { TypographyProps } from '@telus-uds/components-web'
import { VariantColor, VariantSize } from './TypographyVariantTypes'
import { RichTextListProps } from './RichTextList'
import { renderSpacer } from 'src/siteBuilder/utils/renderSpacer'

export type HyperlinkTarget = '_self' | '_blank' | '_parent' | '_top'

export type RichTextProps = {
  paragraphSize?: VariantSize
  spaceBetween?: number
  colour?: VariantColor
  align?: TypographyProps['align']
  spacer?: SpacerProps
  richText: Document
  linkColour?: 'alternative'
  inverse?: boolean
  listIcon?: {
    iconName?: RichTextListProps['iconName']
    iconColor?: RichTextListProps['iconColor']
    iconSize?: RichTextListProps['iconSize']
  }
  isBlog?: boolean
  target?: HyperlinkTarget
}

const RichText = (props: RichTextProps) => {
  if (!props.richText || !props.richText.content?.length) return null

  const options = configOptions(props)

  // colour prop supports secondary and ternary but ternary doesn't exists and secondary is an slightly lighter color than primary. See https://github.com/telus/universal-design-system/issues/2550
  return (
    <div data-testid="rich-text-testid">
      {renderSpacer(props.spacer)}
      {documentToReactComponents(props.richText, options)}
    </div>
  )
}

export default RichText
