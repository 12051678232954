import { FlexGrid, Divider as AlliumDivider } from '@telus-uds/components-web'
import { mapDataAttrsToDataSet } from '../../../utils/dataAttributes'

export type DividerProps = {
  weight?: 'Default' | 'Thick'
  decorative?: boolean
}

const Divider = ({ decorative = false, weight = 'Default', ...rest }: DividerProps) => {
  return (
    <FlexGrid dataSet={mapDataAttrsToDataSet(rest)} gutter={false}>
      <FlexGrid.Row horizontalAlign="center">
        <FlexGrid.Col>
          <AlliumDivider
            variant={{
              weight: weight === 'Default' ? undefined : weight?.toLowerCase(),
              decorative,
            }}
            testID={'allium-divider-testid'}
          />
        </FlexGrid.Col>
      </FlexGrid.Row>
    </FlexGrid>
  )
}

export default Divider
