import React from 'react'

export const cloneElementOfType = <T,>(
  child: React.ReactNode,
  newProps: Partial<T>
): React.ReactElement<T> | React.ReactNode => {
  if (React.isValidElement<T>(child)) return React.cloneElement<T>(child, newProps)

  return child
}
